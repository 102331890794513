import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userServices from "./userService";

const initialState = {
  businessUsers:[],
  businessUserClient:[],
  activeBusinessUser:[],
  assignBusiness:[],
  businessEmployee:[],
  businessUserClientAgainstBusiness:[],
  totalRecord:null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};


export const addNewUser = createAsyncThunk(
    "addNewUser",
    async (data, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await userServices.addUser(user.accesstoken,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


  export const getBusinessUser = createAsyncThunk(
    "getBusinessUser",
    async ({pageno,limit,search},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await userServices.businessUser(user.accesstoken,pageno,limit,search);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const updateUser = createAsyncThunk(
    "updateUser",
    async ({ id, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await userServices.updateUser(user.accesstoken,id,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const activeBussinessUserList = createAsyncThunk(
    "activeBussinessUserList",
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await userServices.activeBussinessUser(user.accesstoken);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const getBusinessUserClient = createAsyncThunk(
    "getBusinessUserClient",
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await userServices.businessClient(user.accesstoken);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const getBusinessUserEmployee = createAsyncThunk(
    "getBusinessUserEmployee",
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await userServices.businessEmployee(user.accesstoken);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


  export const allBusinessUser = createAsyncThunk(
    "allBusinessUser",
    async ({pageno,limit,search},thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await userServices.allBusinessUser(user.accesstoken,pageno,limit,search);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const getAssignedBussiness = createAsyncThunk(
    "getAssignedBussiness",
    async (thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await userServices.assignedBusinessUserList(user.accesstoken);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const businessClientAganistBusiness = createAsyncThunk(
    "businessClientAganistBusiness",
    async (businessId,thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await userServices.businessClientAganistBusiness(user.accesstoken,businessId);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(addNewUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getBusinessUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBusinessUser.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.businessUsers =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
        state.isLoading = false;
      })
      .addCase(getBusinessUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.businessUsers = [];
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(activeBussinessUserList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(activeBussinessUserList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.activeBusinessUser=action?.payload?.body;
      })
      .addCase(activeBussinessUserList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getBusinessUserClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBusinessUserClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.businessUserClient =action?.payload?.body;
      })
      .addCase(getBusinessUserClient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.businessUserClient = [];
      })
      .addCase(getBusinessUserEmployee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBusinessUserEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.businessEmployee =action?.payload?.body;
      })
      .addCase(getBusinessUserEmployee.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.businessEmployee = [];
      })
      .addCase(allBusinessUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allBusinessUser.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.businessUsers =action?.payload?.body;
        state.totalRecord =action?.payload?.total_record;
        state.isLoading = false;
      })
      .addCase(allBusinessUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.businessUsers = [];
      })
      .addCase(businessClientAganistBusiness.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(businessClientAganistBusiness.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.businessUserClientAgainstBusiness =action?.payload?.body;
        state.isLoading = false;
      })
      .addCase(businessClientAganistBusiness.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.businessUserClientAgainstBusiness = [];
      })
      .addCase(getAssignedBussiness.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssignedBussiness.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.assignBusiness =action?.payload?.body;
      })
      .addCase(getAssignedBussiness.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.assignBusiness = [];
      })
  },
});
export const { resets } = userSlice.actions;

export default userSlice.reducer;
