import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;



const createConversation = async (token, receiver_id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${BASE_URL}/conversation/create`,
    receiver_id,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Add Business api failed");
};

const allConversation = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/conversation/list`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Add Business api failed");
};

const getConversationById = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/conversation?id=${id.id}&pageno=${id.page}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Add Business api failed");
};


const chatServices = {
  createConversation,
  allConversation,
  getConversationById,
};

export default chatServices;
