import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const addProjectVersion = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/project/version/add`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Project Version api failed');
};

const getProjectVersion = async (token, projectId, pageno, perPage) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/project/version/${projectId}?page=${pageno}&perPage=${perPage}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Project Version List api failed');
};

const updateProjectVersion = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/project/version/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Project Version api failed');
};

const updateStatusProjectVersion = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/project/version/status/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Project Version Status api failed');
};

const getActiveProjectVersion = async (token, projectId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/project/version/active/${projectId}`, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Active Project Version List api failed');
};

const getSingleProjectVersion = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/project/single/version/${id}`, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Active Project Version List api failed');
};

const updateProjectModule = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/project/module/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Project Module api failed');
};

const projectModuleGet = async (token, projectId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/${projectId}/module`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Project Module List api failed');
};


const addProjectModule = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/project/module/add`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Project Module api failed');
};

const projectVersionServices = {
  addProjectVersion,
  getProjectVersion,
  updateProjectVersion,
  updateStatusProjectVersion,
  getActiveProjectVersion,
  getSingleProjectVersion,
  updateProjectModule,
  projectModuleGet,
  addProjectModule
};

export default projectVersionServices;
