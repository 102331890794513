import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import chatServices from "./chatServices";

const initialState = {
  conversations: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};


export const createConversation = createAsyncThunk(
  "createConversation",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await chatServices.createConversation(user.accesstoken, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllConversation = createAsyncThunk(
  "getAllConversation",
  async (thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await chatServices.allConversation(user.accesstoken);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getConversationById = createAsyncThunk(
  "getConversationById",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await chatServices.getConversationById(user.accesstoken, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);



export const chatSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createConversation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createConversation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.createdConversation = action.payload
      })
      .addCase(createConversation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getAllConversation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllConversation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.allConversations = action.payload
      })
      .addCase(getAllConversation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getConversationById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getConversationById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.singleConversation = action.payload
      })
      .addCase(getConversationById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
  },
});
export const { resets } = chatSlice.actions;

export default chatSlice.reducer;
