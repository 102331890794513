import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;

const uploadProject = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };   
  const response = await axios.post(
    `${BASE_URL}/file/upload/project`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Upload Project File api failed"); 
};

const uploadBlog = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };   
  const response = await axios.post(
    `${BASE_URL}/file/upload/blog`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Upload Blog File api failed"); 
};


const uploadProfile = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };   
  const response = await axios.post(
    `${BASE_URL}/file/upload/profile`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Upload Profile File api failed"); 
};

const deleteFile = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  };   
  const response = await axios.delete(
    `${BASE_URL}/file/upload/delete`,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Upload File Deleted api failed"); 
};

const uploadCourse = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };   
  const response = await axios.post(
    `${BASE_URL}/file/upload/course`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Upload Course File api failed"); 
};

const uploadCourseProfile = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };   
  const response = await axios.post(
    `${BASE_URL}/file/upload/course/profile`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Upload Course Profile api failed"); 
};


const uploadBusiness = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };   
  const response = await axios.post(
    `${BASE_URL}/file/upload/business`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Upload Business api failed"); 
};


const uploadProjectProfile = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };   
  const response = await axios.post(
    `${BASE_URL}/file/upload/project/profile`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Upload Project Profile api failed"); 
};

const UploadFileServices = {
    uploadProject,
    uploadBlog,
    uploadProfile,
    uploadCourse,
    uploadCourseProfile,
    deleteFile,
    uploadBusiness,
    uploadProjectProfile
};

export default  UploadFileServices;
